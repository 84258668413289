import { GlobalState } from "store/interfaces";

export function userIsNmcEngineer(globalState: GlobalState) {
  const userJobTitle = globalState.userDetails.jobTitle;
  return (
    userJobTitle?.toLowerCase().includes("nmc") ||
    userJobTitle?.toLowerCase().includes("nmc engineer") ||
    userJobTitle?.toLowerCase().includes("nmc shift") ||
    userJobTitle?.toLowerCase().includes("nmc manager")
  );
}

export function userIsUsingSharedAccount(globalState: GlobalState) {
  return (
    globalState.userDetails.netsuite_id === -1 ||
    globalState.userDetails.using_shared_netsuite_account ||
    globalState.userDetails.netsuite_id === 0
  );
}

const IT_AND_SYSTEMS_ENGINEER = "IT & Systems Engineer";
const IT_AND_SYSTEMS_USERS = [
  "IT & Systems Engineer",
  "IT Development Manager",
  "Senior IT & System Engineer",
];
export const userInItAndSystems = (globalState: GlobalState) => {
  const jobTitle = globalState.userDetails.jobTitle?.toLowerCase() ?? "";
  return (
    jobTitle?.includes(IT_AND_SYSTEMS_ENGINEER.toLowerCase()) ||
    IT_AND_SYSTEMS_USERS.toString().toLowerCase().includes(jobTitle)
  );
};

export function userInServiceDelivery(globalState: GlobalState) {
  const jobTitle = globalState.userDetails.jobTitle ?? "";
  return (
    globalState.userDetails.is_service_delivery ||
    jobTitle === "Director, Projects, Procurement & Logistics"
  );
}

export function userAllowedToMassUpdateProgrammes(globalState: GlobalState) {
  return userInServiceDelivery(globalState) || userInItAndSystems(globalState);
}

const ROLES_WITH_SALES_REPORT_VIEW = [
  "IT & System Engineer",
  "IT & Systems Engineer",
  "IT Development Manager",
  "Business Analyst",
  "Business Intelligence Assistant",
  "Director, Business Intelligence",
  "VP, OATS",
];

const PRODUCT_MANAGEMENT_ROLES = [
  "Head of Product",
  //"Product Manager"
];

export const userHasRedactedAccessToSalesReport = (
  userDetails: GlobalState["userDetails"]
): boolean => {
  const userJobTitle = userDetails?.jobTitle?.toLowerCase?.();
  if (!userJobTitle) return false;

  return (
    PRODUCT_MANAGEMENT_ROLES.find((v) =>
      userJobTitle.includes(v.toLowerCase())
    ) !== undefined
  );
};

export const userCanViewSalesReports = (
  userDetails: GlobalState["userDetails"]
): boolean => {
  const userJobTitle = userDetails?.jobTitle?.toLowerCase?.();
  if (!userJobTitle) return false;

  if (userJobTitle.includes("group chief")) return true;
  if (userJobTitle.includes("sales")) return true;

  return (
    [...ROLES_WITH_SALES_REPORT_VIEW, ...PRODUCT_MANAGEMENT_ROLES].find((v) =>
      userJobTitle.includes(v.toLowerCase())
    ) !== undefined
  );
};
