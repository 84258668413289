import { lazy } from 'react';
import type { RouteObject } from './interfaces';

const Tsols = lazy(() => import('engineering/diagrams/tsols-v2'));


const diagramRoutes: RouteObject[] = [
	{ path: '/diagrams/tsols/v2', element: Tsols },
	
];

export default diagramRoutes;
