
// import engineeringRoutes from 'engineering/routes';
import COMMERCIAL_ROUTES from "Routes/commercial";
import ENGINEERING_ROUTES from "Routes/engineering";
import { RouteObject } from "Routes/interfaces";
import IT_REQUESTS from "Routes/it-requests";
import OAMS_APP_ROUTES from "Routes/oams";
import diagramRoutes from "Routes/diagrams";

const appRoutes: RouteObject[] = [];

const departments = [
  diagramRoutes,
  ENGINEERING_ROUTES,
  COMMERCIAL_ROUTES,
  IT_REQUESTS,
  OAMS_APP_ROUTES
];

const getAppRoutes = () => {
  let routes = appRoutes;
  departments.forEach((department) => {
    routes = routes.concat(department);
  });

  return routes;
};

export default getAppRoutes();
